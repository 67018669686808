/* eslint-disable react/jsx-no-target-blank */
import { useEffect, useState, Fragment } from "react";
import { Container, Row, Col } from "react-grid-system";
import NavBar from "../Layout/navbar";
import Ellipse from "../assets/images/ellipse.svg";
import Men from "../assets/images/men.svg";
//import ImageCenter from "../assets/images/text-center.svg";
//import Logo from "../assets/images/logo.svg";
import Config from "../Config";
import MePic from "../assets/images/me.png";
import MySkill from "../assets/images/skills.men.png";
import Carousel from "react-multi-carousel";
import {
  RiFacebookFill,
  RiGithubFill,
  RiTwitterFill,
  RiReactjsFill,
  RiServerLine,
} from "react-icons/ri";
import { BsCodeSlash, BsArrowDown } from "react-icons/bs";
import ReactPageScroller from "react-page-scroller";
import Page from "../Layout/page";
import ReactGA from "react-ga";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 478 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 478, min: 0 },
    items: 1,
  },
};

const socialMedia = [
  {
    name: "facebook",
    Icon: () => <RiFacebookFill className="icon" />,
    url: "https://www.facebook.com/meksiabdou",
  },
  {
    name: "github",
    Icon: () => <RiGithubFill className="icon" />,
    url: "https://github.com/meksiabdou",
  },
  {
    name: "twitter",
    Icon: () => <RiTwitterFill className="icon" />,
    url: "https://www.twitter.com/meksiabdou",
  },
];

const Home = (props) => {
  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(0);
  //const [blockScroll, setBlockScroll] = useState(false);

  /*const screenHeight = async (envent) => {
    //console.log(envent);
    const innerHeight = envent.currentTarget.innerHeight;
    const screens = document.querySelectorAll(".screen");

    await Promise.all(
      [...screens].map((elm) => {
        //elm.style.minHeight = `${innerHeight}px`;
        return true;
      })
    );
    setLoading(false);
  };*/

  useEffect(() => {
    //window.addEventListener("resize", screenHeight);
    //return () => window.removeEventListener("resize", screenHeight);
  }, []);

  useEffect(() => {
    window.addEventListener("load", () => setLoading(false));
    return () => window.removeEventListener("load", () => setLoading(false));
  }, []);

  //console.log(loading);
  const data = {
    what_we_do: [
      {
        title: "web development",
        icon: () => <BsCodeSlash className="icon" />,
      },
      {
        title: "React-native development",
        icon: () => <RiReactjsFill className="icon" />,
      },
      {
        title: "Web Hosting",
        icon: () => <RiServerLine className="icon" />,
      },
    ],

    mySkills: [
      {
        title: "Css & Html",
        value: 75,
        label: "Good",
      },
      {
        title: "JavaScript & Nodejs",
        value: 85,
        label: "High",
      },
      {
        title: "ReactJs & NextJs",
        value: 85,
        label: "High",
      },
      {
        title: "React-Native",
        value: 80,
        label: "High",
      },
      {
        title: "PHP & CodeIgniter",
        value: 80,
        label: "High",
      },
      {
        title: "Web Hosting",
        value: 80,
        label: "High",
      },
    ],
    works: [
      {
        title: "Halal ou Pas ?",
        image: "/images/works/halaloupas.png",
        disc: "More Than 100K Downloads in Google Play",
        url: "https://play.google.com/store/apps/details?id=com.harragastudions.halalscan&hl=fr&gl=US",
      },
      {
        title: "Recashit",
        image: "/images/works/recashit.png",
        disc: "CashBack & Coupons",
        url: "https://recashit.meksiabdou.net",
      },
      {
        title: "Code De La Route",
        image: "/images/works/cdr.png",
        disc: "Cours de circulation et de conduite",
        url: null,
      },
      {
        title: "Wordpress To App",
        image: "/images/works/wptoapp.png",
        disc: "Build Android & Ios App For Wordpress News",
        url: null,
      },
      {
        title: "Ghida2i Project",
        image: "/images/works/ghida2i.png",
        disc: "Project ghida2i for food products",
        url: null,
      },
      {
        title: "Specsgate Project",
        image: "/images/works/smartphones.png",
        disc: "Project Specsgate for smartphone & tablets",
        url: "https://specsgate.com/",
      },
      {
        title: "Waste Doc Center Project",
        image: "/images/works/and.png",
        disc: "The digital documentation center of waste doc center",
        url: "https://wastedoccenter.and.dz/ar",
      },
    ],
  };

  return (
    <Fragment>
      <div
        className="ellipse"
        style={{ backgroundImage: `url('${Ellipse}')` }}
      />
      <button
        className="shake-vertical btn btn-default scroll-down"
        id="scroll-down"
        onClick={() => setPage(1)}
      >
        <BsArrowDown className="icon" />
      </button>
      <Container className="App" id="app" fluid>
        <Container>
          <NavBar goToPage={setPage} page={page} />
        </Container>
        <ReactPageScroller
          //blockScrollUp={blockScroll}
          pageOnChange={(index) => setPage(index)}
          customPageNumber={page}
          onBeforePageScroll={(_page) => {
            const app = document.querySelector("#app");
            const scrollDown = document.querySelector("#scroll-down");
            if (app) {
              if (_page !== 0) {
                app.style.background = "#16161f";
                scrollDown.style.opacity = "0";
                scrollDown.style.zIndex = "0";
              } else {
                app.style.background = "none";
                scrollDown.style.opacity = "1";
                scrollDown.style.zIndex = "9999";
              }
            }
          }}
        >
          <Page>
            <div className="center home h-100" style={{ marginTop: "-40px" }}>
              <div data-aos="fade-right" className="right-center">
                <div className="title w-100 tracking-in-expand">
                  <span>IM</span>{" "}
                  <span className="logo-text">{Config.logoName}</span>
                  <p className="big">{Config.subLogoName}</p>
                  <p className="small">
                    <span className="sub-text">{Config.jobName} </span>
                    <span>{Config.subJsobName}</span>
                  </p>
                </div>
              </div>
              <div data-aos="fade-left" className="left-center">
                <img
                  src={Men}
                  alt=""
                  className="vibrate-1 home-image"
                  style={{ marginLeft: "-15%" }}
                />
              </div>
            </div>
          </Page>
          <Page className="about" id="about">
            <div className="w-100 h-100 center">
              <div className="center about-view">
                <div className="profile-image">
                  <img src={MePic} alt={Config.name} />
                </div>
                <h1>{Config.name}</h1>
                <p>{Config.textAbout}</p>
                {/*<ReactGA.OutboundLink
                  eventLabel="cvDown"
                  to="https://drive.google.com/file/d/1sTmVyLm_DzGoxnFLCxuQk1LUYL3QGbzA/view?usp=sharing"
                  target="_blank"
                  trackerNames={["cvTracker"]}
                >
                  <button className="btn btn-default border-default">
                    Téléchargement de CV
                  </button>
                </ReactGA.OutboundLink>*/}
              </div>
            </div>
          </Page>
          <Page
            className="what_we_do"
            id="protfolio"
            title={"What <br/> We Do"}
          >
            <Row>
              {data.what_we_do.map((item, index) => {
                const col = 4;
                return (
                  <Col key={index} xxl={col} xl={col} md={col} sm={12} xs={12}>
                    <div className="item border-default">
                      {item.icon()}
                      <p>{item.title}</p>
                    </div>
                  </Col>
                );
              })}
            </Row>
          </Page>
          <Page className="my_skills" id="skills" title={"My <br/> Skills"}>
            <Row>
              <Col
                xxl={6}
                xl={6}
                lg={7}
                md={7}
                sm={12}
                xs={12}
                className="skills-left"
              >
                <div className="p-relative center">
                  <div className="bg-circular" />
                  <img src={MySkill} alt="" />
                </div>
              </Col>
              <Col xxl={6} xl={6} lg={5} md={5} sm={12} xs={12}>
                <div className="center flex-col h-100 skills-bar">
                  {data.mySkills.map((item, index) => {
                    let value = item.value;
                    return (
                      <div key={index} className={"skills w-100"}>
                        <div className="title">
                          <p>{item.title}</p>
                          <span>{`${item.label}`}</span>
                        </div>
                        <div className="skill-input border-default w-100">
                          <div
                            className="bg-circular"
                            style={{ width: `${value}%` }}
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
              </Col>
            </Row>
          </Page>
          <Page
            className="select_work background-default"
            id="works"
            title={"Select <br/> Work"}
            fluid={true}
          >
            <Carousel
              infinite={false}
              //removeArrowOnDeviceType={["tablet", "mobile"]}
              swipeable={false}
              responsive={responsive}
            >
              {data.works.map((item, index) => {
                return (
                  <ReactGA.OutboundLink
                    eventLabel="cvDown"
                    to={item.url}
                    target="_blank"
                    trackerNames={["selectWorkTracker"]}
                  >
                    <div className="item" key={index}>
                      <div className="image border-default">
                        <img src={item.image} alt="" />
                      </div>
                      <h1 className="title">{item.title}</h1>
                      <span>{item.disc}</span>
                    </div>
                  </ReactGA.OutboundLink>
                );
              })}
            </Carousel>
          </Page>
          <Page className="contact" id="contact" title={"contact"}>
            <Col>
              <div className="center social flex-col">
                <div className="email">
                  <p>{Config.email}</p>
                </div>
                <div className="center">
                  {socialMedia.map((item, index) => {
                    return (
                      <a href={item.url} key={index}>
                        {item.Icon()}
                      </a>
                    );
                  })}
                </div>
                <div className="footer">
                  <span>Developer By Meksi Abdennour</span>
                  <span>Designed By Abdallah Haddad</span>
                </div>
              </div>
            </Col>
          </Page>
        </ReactPageScroller>
      </Container>
      {loading && <div className="fullscreen" />}
    </Fragment>
  );
};

export default Home;
