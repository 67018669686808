import { Col, Container, Row } from "react-grid-system";

const Page = ({ children, title, className, id, fluid }) => {
  return (
    <Container fluid className="h-100 p-0">
      <div className="center screen h-100 mobile" style={{flexDirection: 'column'}}>
        <Container className={`w-100`} id={id}>
          {title ? (
            <Row>
              <Col xxl={12} xl={12} lg={12} md={12} sm={12} xs={12}>
                <h1
                  className="title"
                  dangerouslySetInnerHTML={{ __html: title }}
                />
              </Col>
            </Row>
          ) : <span />}
        </Container>
        <Container className={`${className || ""} w-100`} fluid={fluid}>{children || children}</Container>
      </div>
    </Container>
  );
};

export default Page;
