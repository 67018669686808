import { useEffect } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./pages/Home";
import AOS from "aos";
import ReactGA from 'react-ga';
ReactGA.initialize('UA-67520235-4');

function App() {
  useEffect(() => {
    AOS.init({duration: 1500});
    AOS.refresh();
  }, []);

  //console.log(loading);

  return (
    <Router>
      <Switch>
        <Route path="/" component={Home} />
      </Switch>
    </Router>
  );
}

export default App;
