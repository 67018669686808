import { Row, Col } from "react-grid-system";
import Logo from "../assets/images/logo.svg";
import { Menu, MenuItem, MenuButton } from "@szhsin/react-menu";
import { AiOutlineMenu } from "react-icons/ai";

const NavBar = ({ goToPage, page }) => {
  const list = [
    {
      title: "Home",
      url: "./#",
    },
    {
      title: "About",
      url: "./#",
    },
    {
      title: "Protfolio",
      url: "./#",
    },
    {
      title: "Skills",
      url: "./#",
    },
    {
      title: "Select Work",
      url: "./#",
    },
    {
      title: "Contact",
      url: "./#",
    },
  ];


  return (
    <Row>
      <Col className="nav-bar">
        <div className="logo">
          <img src={Logo} alt="MEKSI" />
          <span>ABDENNOUR</span>
        </div>
        <div className="nav desktop">
          <ul>
            {list.map((item, index) => {
              return (
                <li key={index} className={`item-nav ${page === index ? "active" : ''}`}>
                  <a href={item.url} onClick={() => goToPage(index)}>
                    {item.title}
                  </a>
                </li>
              );
            })}
          </ul>
        </div>
        <div className="mobile">
          <Menu
            className="background-default nav"
            menuButton={
              <MenuButton className="btn btn-default">
                <AiOutlineMenu />
              </MenuButton>
            }
          >
            {list.map((item, index) => {
              return (
                <MenuItem key={index}>
                  <a href={item.url} onClick={() => goToPage(index)}>
                    {item.title}
                  </a>
                </MenuItem>
              );
            })}
          </Menu>
        </div>
      </Col>
    </Row>
  );
};

export default NavBar;
